import React, { useState, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import ReactQuill from 'react-quill'
import ReactTooltip from 'react-tooltip'
import { translate } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Switch } from 'antd'

import { read } from '../../helpers/translationModel/read'
import { write } from '../../helpers/translationModel/write'
import { firebaseDb as db } from '../../firebase'
import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import UploadSingleImage from '../../components/FormInputs/UploadSingleImage'
import FileSelector from '../FileStorage/Parts/FileSelector'

const CalHeaderTop = props => {
  const { t } = props

  const [display, setDisplay] = useState(true)
  const [title, setTitle] = useState('')
  const [linkLabel, setLinkLabel] = useState('default')
  const [linkUrl, setLinkUrl] = useState('default')
  const [text, setText] = useState('Loading, please wait ....')
  const [loading, setLoading] = useState(true)
  const [colors, setColors] = useState()
  const [modules, setModules] = useState()
  const [headerMedia, setHeaderMedia] = useState({ url: '' })
  const [headerVideoDisplay, setHeaderVideoDisplay] = useState(false)
  const [separateTextImage, setSeparateTextImage] = useState(false)
  const [separateTextImageMobile, setSeparateTextImageMobile] = useState(false)
  const [headerSolidBackground, setHeaderSolidBackground] = useState(false)
  const [usePriceModal, setUsePriceModal] = useState(false)

  const {
    activeCompany,
    activeCalendar,
    calendarLangPrimary,
    calendarLang
  } = useSelector(state => state)

  useEffect(() => {
    if (activeCompany && activeCalendar) {
      loadFromDb()
    }
  }, [activeCompany, activeCalendar, calendarLang])

  useEffect(() => {
    setHeaderMedia({
      ...headerMedia,
      hideOnMobile: !(headerVideoDisplay &&
        headerMedia.contentType &&
        headerMedia.contentType.startsWith('video'))
    })
  }, [headerVideoDisplay])

  const loadFromDb = () => {
    activeCompany &&
      activeCalendar &&
      db
        .doc(
          'companies/' +
          activeCompany +
          '/calendars/' +
          activeCalendar +
          '/settings/grid'
        )
        .get()
        .then(async querySnapshot => {
          let data = querySnapshot.data()

          // Replace data with translations
          data = await read(
            activeCompany,
            activeCalendar,
            calendarLangPrimary,
            calendarLang,
            'settings/grid',
            data
          )

          // console.log('data', data)

          const modules = {
            toolbar: [
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, false] }],
              [{ align: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
              ],
              ['link', 'image'],
              [{ color: Object.values(data.colors) }, { color: [] }],
              ['clean']
            ]
          }
          setTitle(data.header.title || '')
          setText(data.header.text || '')
          setDisplay(data.header.display)
          setLinkLabel(data.header.linkLabel || '')
          setLinkUrl(data.header.linkUrl || '')
          setHeaderMedia(data.header.headerMedia || { url: '' })
          setHeaderVideoDisplay(!(data.header.headerMedia && data.header.headerMedia.hideOnMobile))
          setHeaderSolidBackground(data.header.headerSolidBackground || false)
          setSeparateTextImage(data.header.separateTextImage || false)
          setSeparateTextImageMobile(data.header.separateTextImageMobile || false)
          setColors(colors)
          setModules(modules)
          setLoading(false)
          setUsePriceModal(data.header.usePriceModal || false)
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  const notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const quillEditor = document.querySelector('.ql-editor').innerHTML

    let updateContent = {
      header: {
        title: title,
        text: quillEditor,
        display: display,
        linkLabel: linkLabel,
        linkUrl: linkUrl,
        headerMedia: headerMedia,
        headerSolidBackground: headerSolidBackground,
        separateTextImage: separateTextImage,
        separateTextImageMobile: separateTextImageMobile,
        usePriceModal: usePriceModal
      }
    }
    activeCalendar &&
      write(
        activeCompany,
        activeCalendar,
        calendarLangPrimary,
        calendarLang,
        'settings/grid',
        updateContent
      )
        .then(() => {
          db.doc('companies/' + activeCompany + '/calendars/' + activeCalendar)
            .update({ changeToPublish: true })
            .then(res => { })
            .catch(error => {
              console.log(error)
            })
          notificationOpen('success')
        })
        .catch(error => {
          notificationOpen('error', error)
        })
  }

  // Void function
  const handleChangeQuill = (e) => { setText(e) }
  return (
    <>
      <Row
        className='show-grid'
        float='center'
        style={loading ? { display: 'block' } : { display: 'none' }}
      >
        <Col xs={12} xsOffset={6} valign='middle'>
          <Loading type='spin' color='green' />
        </Col>
      </Row>

      <Grid
        fluid
        style={!loading ? { visibility: 'visible' } : { visibility: 'hidden' }}
      >
        <Row>
          <Col md={12}>
            <Card
              title={t('calHeader.editHeader')}
              content={
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel>
                          <div className='checkbox'>
                            <input
                              type='checkbox'
                              id='display'
                              name='display'
                              checked={display}
                              onChange={e => setDisplay(e.target.checked)}
                            />
                            <label htmlFor='display'>
                              {t('calHeader.displayHeader')}
                            </label>
                          </div>
                        </ControlLabel>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('header.display')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 0 0 5px',
                            fontSize: '15px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      display: display === true ? 'block' : 'none'
                    }}
                  >
                    <Col md={6}>
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('header.headerImage')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calHeader.headerImage')}
                        </ControlLabel>
                        <FileSelector
                          activeCompany={activeCompany}
                          activeCalendar={activeCalendar}
                          fileName='headerImage'
                          pathToUpload='header/'
                          dbPath='settings/grid'
                          dbField='headerImage'
                          url={headerMedia.url}
                          onSelect={(file) => setHeaderMedia({
                            url: file.url,
                            contentType: file.metadata && file.metadata.contentType,
                          })}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <ControlLabel>
                              <div className='checkbox'>
                                <input
                                  type='checkbox'
                                  id='displayVideoHeader'
                                  name='displayVideoHeader'
                                  checked={headerVideoDisplay}
                                  disabled={!(headerMedia.contentType &&
                                    headerMedia.contentType.startsWith('video'))}
                                  onChange={e => setHeaderVideoDisplay(e.target.checked)}
                                />
                                <label htmlFor='displayVideoHeader'>
                                  {t('calHeader.displayVideoHeader')}
                                </label>
                              </div>
                            </ControlLabel>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('header.display')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 0 0 5px',
                                fontSize: '15px'
                              }}
                            />
                          </div>
                          <div>
                            <ControlLabel>
                              <div className='checkbox'>
                                <input
                                  type='checkbox'
                                  id='separateTextImage'
                                  name='separateTextImage'
                                  checked={separateTextImage}
                                  onChange={e => setSeparateTextImage(e.target.checked)}
                                />
                                <label htmlFor='separateTextImage'>
                                  {t('calHeader.separateTextImage')}
                                </label>
                              </div>
                            </ControlLabel>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('header.display')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 0 0 5px',
                                fontSize: '15px'
                              }}
                            />
                          </div>
                          <div>
                            <ControlLabel>
                              <div className='checkbox'>
                                <input
                                  type='checkbox'
                                  id='separateTextImageMobile'
                                  name='separateTextImageMobile'
                                  checked={separateTextImageMobile}
                                  disabled={!separateTextImage}
                                  onChange={e => setSeparateTextImageMobile(e.target.checked)}
                                />
                                <label htmlFor='separateTextImageMobile'>
                                  {t('calHeader.separateTextImageMobile')}
                                </label>
                              </div>
                            </ControlLabel>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('header.display')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 0 0 5px',
                                fontSize: '15px'
                              }}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('header.title')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>{t('calHeader.title')}</ControlLabel>
                        <FormControl
                          type='text'
                          name='title'
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                          id='header'
                          placeholder={t('calHeader.titleOfHeader')}
                          defaultValue={title}
                        />
                      </FormGroup>
                      <FormGroup>
                        <i
                          className='pe-7s-help1 text-success'
                          data-tip={t('header.text')}
                          style={{
                            position: 'relative',
                            top: '1px',
                            padding: '5px 5px 0 0',
                            fontSize: '15px'
                          }}
                        />
                        <ControlLabel>
                          {t('calHeader.textOfHeader')}
                        </ControlLabel>
                        {modules && (
                          <ReactQuill
                            value={text}
                            modules={modules}
                            name='content'
                            onChange={handleChangeQuill}
                          />
                        )}
                      </FormGroup>

                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('header.linkLabel')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calHeader.linkLabel')}
                            </ControlLabel>
                            <FormControl
                              type='text'
                              name='linkLabel'
                              value={linkLabel}
                              onChange={e => setLinkLabel(e.target.value)}
                              id='linkLabel'
                              placeholder={t('calHeader.linkLabelDesc')}
                              defaultValue={linkLabel}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('header.linkUrl')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calHeader.linkUrl')}
                            </ControlLabel>
                            <FormControl
                              type='text'
                              name='linkUrl'
                              value={linkUrl}
                              onChange={e => setLinkUrl(e.target.value)}
                              id='linkUrl'
                              placeholder={t('calHeader.linkUrlDesc')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('calHeader.usePriceModal')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calHeader.usePriceModal')}
                            </ControlLabel>
                            <div>
                              <Switch checked={usePriceModal} onChange={(value) => setUsePriceModal(value)} />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <ControlLabel>
                            <div className='checkbox'>
                              <input
                                type='checkbox'
                                id='headerSolidBackground'
                                name='headerSolidBackground'
                                checked={headerSolidBackground}
                                onChange={e => setHeaderSolidBackground(e.target.checked)}
                              />
                              <label htmlFor='headerSolidBackground'>
                                {t('calHeader.headerSolidBackground')}
                              </label>
                            </div>
                          </ControlLabel>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Button bsStyle='info' pullRight fill type='submit'>
                    {t('calHeader.updateHeader')}
                  </Button>
                  <div className='clearfix' />
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
      <ReactTooltip place='top' type='dark' effect='solid' />
    </>
  )
}

export default translate('translations')(CalHeaderTop)
