import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from 'react-bootstrap'
import { Card } from '../../components/Card/Card'
// import { FormInputs } from '../../components/FormInputs/FormInputs'
import Button from '../../components/CustomButton/CustomButton'
import Loading from '../../components/Loading/Loading'
import { firebaseDb as db } from '../../firebase'
import ReactTooltip from 'react-tooltip'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import ColorsPredefiner from './Parts/ColorsPredefiner'
import Modal from './Parts/AnimationGallery'
import { firebaseConfig } from '../../firebase/config'
import FontPicker from '../../components/FontPicker/FontPicker'
import FileSelector from '../FileStorage/Parts/FileSelector'
import { Input } from 'antd'


class CalStyle extends Component {
  state = {
    colorPicker: null,
    colorsGrid: null,
    displayDayNumber: true,
    loading: true,
    showAnimationGallery: false
  }

  componentDidMount() {
    this.setState(
      {
        ...this.state,
        activeCompany: this.props.activeCompany,
        activeCalendar: this.props.activeCalendar
      },
      this.loadFromDb
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ...this.state,
        activeCompany: nextProps.activeCompany,
        activeCalendar: nextProps.activeCalendar
      },
      this.loadFromDb
    )
  }

  loadFromDb() {
    db.doc(
      'companies/' +
      this.state.activeCompany +
      '/calendars/' +
      this.state.activeCalendar +
      '/settings/grid'
    )
      .get()
      .then(querySnapshot => {
        let data = querySnapshot.data()
        document.getElementById('customCss').value = data.customCss
        this.setState({
          ...this.state,
          colorsGrid: data.colors,
          displayDayNumber: data.displayDayNumber,
          boxAnimation: data.boxAnimation,
          logoAlignment: data.logoAlignment ? data.logoAlignment : 'left',
          loading: false,
          logoWidth: !data.logoWidth || data.logoWidth === null ? 200 : data.logoWidth,
          activeFontFamily: data.fontFamily || 'Helvetica',
          enableFontOverride: data.enableFontOverride,
          logoStorage: data.logoStorage || '',
          optionalLogos: data.optionalLogos || '',
          backgroundStorage: data.backgroundStorage || '',
          mobileBackgroundStorage: data.mobileBackgroundStorage || '',
          faviconStorage: data.faviconStorage || '',
          buttonColor: data.buttonColor || '',
          buttonBackground: data.buttonBackground || '',
          buttonHoverColor: data.buttonHoverColor || '',
          buttonHoverBackground: data.buttonHoverBackground || '',
          buttonBorderType: data.buttonBorderType || '',
          buttonBorderWidth: data.buttonBorderWidth || '',
          buttonBorderRadius: data.buttonBorderRadius || '',
          buttonFontSize: data.buttonFontSize || '',
          boxBackgroundColor: data.boxBackgroundColor || '#ffffff',
          languages: data.languages
        })
        document.getElementById('numGridCols').value = data.numGridCols
      })
      .catch(error => {
        this.notificationOpen('error', error)
      })
  }

  handleSubmit = event => {
    event.preventDefault()
    const data = new FormData(event.target)
    const udpateContent = {
      numGridCols: Number(data.get('numGridCols')),
      customCss: data.get('customCss'),
      displayDayNumber: this.state.displayDayNumber,
      colors: Object.assign({}, this.state.colorsGrid),
      boxAnimation: this.state.boxAnimation,
      logoAlignment: this.state.logoAlignment,
      logoWidth: this.state.logoWidth,
      fontFamily: this.state.activeFontFamily,
      enableFontOverride: this.state.enableFontOverride || false,
      faviconUrl: this.state.faviconUrl || '',
      logoStorage: this.state.logoStorage || '',
      optionalLogos: this.state.optionalLogos || '',
      backgroundStorage: this.state.backgroundStorage || '',
      mobileBackgroundStorage: this.state.mobileBackgroundStorage || '',
      faviconStorage: this.state.faviconStorage || '',
      buttonColor: data.get('buttonColor') || '',
      buttonBackground: data.get('buttonBackground') || '',
      buttonHoverColor: data.get('buttonHoverColor') || '',
      buttonHoverBackground: data.get('buttonHoverBackground') || '',
      buttonBorderType: data.get('buttonBorderType') || '',
      buttonBorderWidth: data.get('buttonBorderWidth') || '',
      buttonBorderRadius: data.get('buttonBorderRadius') || '',
      buttonFontSize: data.get('buttonFontSize') || '',
      boxBackgroundColor: this.state.boxBackgroundColor || ''
    }
    db.doc(
      'companies/' +
      this.state.activeCompany +
      '/calendars/' +
      this.state.activeCalendar +
      '/settings/grid'
    )
      .update(udpateContent)
      .then(() => {
        db.doc(
          'companies/' +
          this.state.activeCompany +
          '/calendars/' +
          this.state.activeCalendar
        )
          .update({ changeToPublish: true })
          .then(res => { })
          .catch(error => {
            console.log(error)
          })
        this.notificationOpen('success')
      })
      .catch(error => {
        this.notificationOpen('error', error)
      })
  }

  handleChangeColors = colors => {
    this.setState({ ...this.state, colorsGrid: colors })
  }

  notificationOpen = (type, errorMessage = '') => {
    if (type === 'success') {
      this.props.notificationOpen('tr', 'pe-7s-check', 'success', 'saved')
    } else if (type === 'error') {
      const message = 'not saved! Error: ' + errorMessage
      this.props.notificationOpen('tr', 'pe-7s-close-circle', 'error', message)
    }
  }

  handleChangeDisplayDayNumber = event => {
    this.setState({
      ...this.state,
      displayDayNumber: event.target.value === 'true'
    })
  }

  handleChangeBoxAnimation = event => {
    this.setState({ ...this.state, boxAnimation: event.target.value })
  }

  handleChangeLogoAlignment = event => {
    this.setState({ ...this.state, logoAlignment: event.target.value })
  }

  handleChangeBoxAnimationGallery = animation => {
    this.setState({ ...this.state, boxAnimation: animation })
  }

  handleChangeBoxAnimationGallery = animation => {
    this.setState({ ...this.state, boxAnimation: animation })
  }

  toggleAnimationGallery = e => {
    e.preventDefault()
    this.setState({
      ...this.state,
      showAnimationGallery: !this.state.showAnimationGallery
    })
  }

  handleChangeLogoWidth = event => {
    event.preventDefault()
    if (event.target.value > 400) {
      event.target.value = 400
    }
    if (event.target.value < 0) {
      event.target.value = 0
    }
    this.setState({ ...this.state, logoWidth: event.target.value })
  }

  render() {
    const { t } = this.props

    return (
      <div className='content'>
        <Modal
          show={this.state.showAnimationGallery}
          boxAnimation={this.state.boxAnimation}
          customClass='modal'
          handleChangeBoxAnimation={this.handleChangeBoxAnimationGallery}
          closeCallback={this.toggleAnimationGallery}
        >
          <React.Fragment>gfddggdg</React.Fragment>
        </Modal>
        <Row
          className='show-grid'
          float='center'
          style={
            this.state.loading ? { display: 'block' } : { display: 'none' }
          }
        >
          <Col xs={12} xsOffset={6} valign='middle'>
            <Loading type='spin' color='green' />
          </Col>
        </Row>
        <Grid
          fluid
          style={
            !this.state.loading
              ? { visibility: 'visible' }
              : { visibility: 'hidden' }
          }
        >
          <Row>
            <Col md={12}>
              <Card
                title={t('calStyle.calendarStyling')}
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <ControlLabel>
                            {t('calStyle.designManualForImages')}
                          </ControlLabel>
                          <div>
                            <a
                              href='https://firebasestorage.googleapis.com/v0/b/w4-calendar-builder-dev.appspot.com/o/download%2Fcalendar_sizes.pdf?alt=media&token=3151076d-c505-4a10-b7b9-37aa3d4b576b'
                              target='_blank'
                              rel='noopener noreferrer'
                              className='btn btn-md btn-success'
                            >
                              {t('calStyle.downloadDesignManual')}
                            </a>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} lg={6}>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.colors')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calStyle.themeColors')}
                          </ControlLabel>
                          <ColorsPredefiner
                            handleChangeColors={this.handleChangeColors}
                            defaultColors={this.state.colorsGrid}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.logo')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>{t('calStyle.logo')}</ControlLabel>
                          <FileSelector
                            type='image'
                            activeCompany={this.state.activeCompany}
                            activeCalendar={this.state.activeCalendar}
                            legacy
                            fileName='logo'
                            pathToUpload='logo/'
                            dbPath='settings/grid'
                            dbField='logo'
                            url={this.state.logoStorage}
                            onSelect={(file) => this.setState({ logoStorage: file.url })}
                          />
                          <FormControl
                            componentClass='select'
                            name='logoAlignment'
                            placeholder='select'
                            style={{ width: '200px' }}
                            value={
                              this.state.logoAlignment &&
                              this.state.logoAlignment
                            }
                            onChange={this.handleChangeLogoAlignment}
                            ref='logoAlignment'
                          >
                            <option value='left'>
                              {t('calStyle.displayLogoLeft')}
                            </option>
                            <option value='center'>
                              {t('calStyle.displayLogoCenter')}
                            </option>
                            <option value='right'>
                              {t('calStyle.displayLogoRight')}
                            </option>
                          </FormControl>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.logoWidth')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calStyle.logoWidth')}
                          </ControlLabel>
                          <FormControl
                            type='number'
                            name='logoWidth'
                            id='logoWidth'
                            style={{ width: '200px' }}
                            value={this.state.logoWidth}
                            onChange={this.handleChangeLogoWidth}
                            max={400}
                            min={0}
                          />
                          {
                            this.state.languages && Object.keys(this.state.languages).sort().map((language, index) => {
                              return (
                                <div key={index}>
                                  <i
                                    className='pe-7s-help1 text-success'
                                    data-tip={t('calStyle.logo')}
                                    style={{
                                      position: 'relative',
                                      top: '1px',
                                      padding: '5px 5px 0 0',
                                      fontSize: '15px'
                                    }}
                                  />
                                  <ControlLabel>{`${this.state.languages[language]} ${t('calStyle.logo')}`}</ControlLabel>
                                  <FileSelector
                                    type='image'
                                    activeCompany={this.state.activeCompany}
                                    activeCalendar={this.state.activeCalendar}
                                    url={(this.state.optionalLogos && this.state.optionalLogos[language]) || ''}
                                    onSelect={(file) => this.setState({ optionalLogos: { ...this.state.optionalLogos, [language]: file.url } })}
                                  />
                                </div>
                              )
                            })
                          }

                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={5}>
                        <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('style.colors')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calStyle.boxBackgroundColor')}
                            </ControlLabel>
                          </div>
                          <Input type='color'
                            value={this.state.boxBackgroundColor}
                            style={{ width: '70%', marginRight: 8 }}
                            onChange={(e) => this.setState({ boxBackgroundColor: e.target.value })}
                          />
                        </FormGroup>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip='Select door animation for boxes'
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>Box animation</ControlLabel>
                          <FormControl
                            componentClass='select'
                            name='boxAnimation'
                            placeholder='select'
                            value={this.state.boxAnimation}
                            onChange={this.handleChangeBoxAnimation}
                            ref='boxAnimation'
                          >
                            <option value='Window'>
                              {t('calStyle.animationGallery.animations.window')}
                            </option>
                            <option value='Single door - left'>
                              {t(
                                'calStyle.animationGallery.animations.singleRollDoor.ToTheLeft'
                              )}
                            </option>
                            <option value='Single door - right'>
                              {t(
                                'calStyle.animationGallery.animations.singleRollDoor.ToTheRight'
                              )}
                            </option>
                            <option value='Single door - top'>
                              {t(
                                'calStyle.animationGallery.animations.singleRollDoor.ToTheTop'
                              )}
                            </option>
                            <option value='Single door - bottom'>
                              {t(
                                'calStyle.animationGallery.animations.singleRollDoor.ToTheBottom'
                              )}
                            </option>
                            <option value='Starship'>
                              {t(
                                'calStyle.animationGallery.animations.starship'
                              )}
                            </option>
                            <option value='Fade out'>
                              {t(
                                'calStyle.animationGallery.animations.fadeOut'
                              )}
                            </option>
                            <option value='Flip 180 - horizontal'>
                              {t(
                                'calStyle.animationGallery.animations.flip.HorizontalFlip'
                              )}
                            </option>
                            <option value='Flip 180 - vertical'>
                              {t(
                                'calStyle.animationGallery.animations.flip.VerticalFlip'
                              )}
                            </option>
                          </FormControl>
                          <ControlLabel>
                            Open the{' '}
                            <a
                              href='/calstyle'
                              onClick={this.toggleAnimationGallery}
                            >
                              Animation Gallery
                            </a>
                            .
                          </ControlLabel>
                        </FormGroup>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.displayDayNumber')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calStyle.displayDayNumberInBox')}
                          </ControlLabel>
                          <FormControl
                            componentClass='select'
                            name='displayDayNumber'
                            placeholder='select'
                            value={
                              this.state.displayDayNumber &&
                              this.state.displayDayNumber.toString()
                            }
                            onChange={this.handleChangeDisplayDayNumber}
                            ref='displayDayNumber'
                          >
                            <option value='true'>
                              {t('calStyle.displayDayNumberInBoxYes')}
                            </option>
                            <option value='false'>
                              {t('calStyle.displayDayNumberInBoxNo')}
                            </option>
                          </FormControl>
                        </FormGroup>
                        <FormGroup>
                          <FormGroup>
                            <i
                              className='pe-7s-help1 text-success'
                              data-tip={t('style.numGridCols.help')}
                              style={{
                                position: 'relative',
                                top: '1px',
                                padding: '5px 5px 0 0',
                                fontSize: '15px'
                              }}
                            />
                            <ControlLabel>
                              {t('calStyle.numGridCols.title')}
                            </ControlLabel>
                            <FormControl
                              type='number'
                              name='numGridCols'
                              step={1}
                              min={1}
                              max={30}
                              id='numGridCols'
                              placeholder={t(
                                'calStyle.numGridCols.placeholder'
                              )}
                            />
                          </FormGroup>
                        </FormGroup>
                        <Col md={6} style={{ display: 'flex', gap: '50px' }}>
                          <div>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonColor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonColor')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonColor'
                                id='buttonColor'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonColor}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonHoverColor')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonHoverColor')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonHoverColor'
                                id='buttonHoverColor'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonHoverColor}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonBorderWidth')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonBorderWidth')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonBorderWidth'
                                id='buttonBorderWidth'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonBorderWidth}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonBorderRadius')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonBorderRadius')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonBorderRadius'
                                id='buttonBorderRadius'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonBorderRadius}
                              />
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonBackground')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonBackground')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonBackground'
                                id='buttonBackground'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonBackground}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonHoverBackground')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonHoverBackground')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonHoverBackground'
                                id='buttonHoverBackground'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonHoverBackground}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonBorderType')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonBorderType')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonBorderType'
                                id='buttonBorderType'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonBorderType}
                              />
                            </FormGroup>
                            <FormGroup>
                              <i
                                className='pe-7s-help1 text-success'
                                data-tip={t('calStyle.buttonFontSize')}
                                style={{
                                  position: 'relative',
                                  top: '1px',
                                  padding: '5px 5px 0 0',
                                  fontSize: '15px'
                                }}
                              />
                              <ControlLabel>{t('calStyle.buttonFontSize')}</ControlLabel>
                              <FormControl
                                type='text'
                                name='buttonFontSize'
                                id='buttonFontSize'
                                style={{ width: '200px' }}
                                defaultValue={this.state.buttonFontSize}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Col>
                      <Col md={6} mdOffset={1}>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.backgroundImage')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calStyle.backgroundImage')}
                          </ControlLabel>
                          <FileSelector
                            type='image'
                            legacy
                            activeCompany={this.state.activeCompany}
                            activeCalendar={this.state.activeCalendar}
                            fileName='backgroundImage'
                            pathToUpload='bg/'
                            dbPath='settings/grid'
                            dbField='backgroundImage'
                            url={this.state.backgroundStorage}
                            onSelect={(file) => this.setState({ backgroundStorage: file.url })}
                          />
                        </FormGroup>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('calStyle.mobileBackgroundImage')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>
                            {t('calStyle.mobileBackgroundImage')}
                          </ControlLabel>
                          <FileSelector
                            type='image'
                            legacy
                            activeCompany={this.state.activeCompany}
                            activeCalendar={this.state.activeCalendar}
                            fileName='mobileBackgroundImage'
                            pathToUpload='mobileBg/'
                            dbPath='settings/grid'
                            dbField='mobileBackgroundImage'
                            url={this.state.mobileBackgroundStorage}
                            onSelect={(file) => this.setState({ mobileBackgroundStorage: file.url })}
                          />
                        </FormGroup>
                        <FormGroup>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.favicon')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>{t('calStyle.favicon')}</ControlLabel>
                          <FileSelector
                            type='image'
                            legacy
                            small
                            activeCompany={this.state.activeCompany}
                            activeCalendar={this.state.activeCalendar}
                            fileName='favicon'
                            pathToUpload='favicon/'
                            dbPath='settings/grid'
                            dbField='favicon'
                            url={this.state.faviconStorage}
                            onSelect={(file) => this.setState({ faviconStorage: file.url })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>

                      <Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='checkbox'>
                          <FormControl
                            type='checkbox'
                            id='enableFontOverride'
                            name='enableFontOverride'
                            checked={this.state.enableFontOverride}
                            onChange={e =>
                              this.setState({ enableFontOverride: e.target.checked })
                            }
                          />
                          <label htmlFor='enableFontOverride'>
                            {t('calStyle.enableFontOverride')}
                          </label>
                        </div>
                        <FormGroup style={{
                          opacity: this.state.enableFontOverride ? 1.0 : 0.5,
                          pointerEvents: this.state.enableFontOverride ? 'all' : 'none'
                        }}>
                          <i
                            className='pe-7s-help1 text-success'
                            data-tip={t('style.selectFont')}
                            style={{
                              position: 'relative',
                              top: '1px',
                              padding: '5px 5px 0 0',
                              fontSize: '15px'
                            }}
                          />
                          <ControlLabel>{t('calStyle.selectFont')}</ControlLabel>
                          <FontPicker
                            apiKey={firebaseConfig.apiKey}
                            limit={150}
                            activeFontFamily={this.state.activeFontFamily}
                            extraFonts={['Helvetica', 'Droid Sans Mono', 'Arial']}
                            onChange={(nextFont) =>
                              this.setState({
                                activeFontFamily: nextFont.family,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <i
                        className='pe-7s-help1 text-success'
                        data-tip={t('style.customCss')}
                        style={{
                          position: 'relative',
                          top: '1px',
                          padding: '5px 5px 0 0',
                          fontSize: '15px'
                        }}
                      />
                      <ControlLabel>
                        {t('calStyle.customCss')}{' '}
                        <span className='warning'>
                          {t('calStyle.cssEditor')}
                        </span>
                      </ControlLabel>
                      <FormControl
                        rows='10'
                        componentClass='textarea'
                        bsClass='form-control'
                        name='customCss'
                        id='customCss'
                        placeholder={t('calStyle.cssEditorDesc')}
                        defaultValue=''
                      />
                    </FormGroup>
                    <Button bsStyle='info' pullRight fill type='submit'>
                      {t('calStyle.updateStyling')}
                    </Button>
                    <div className='clearfix' />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
        <ReactTooltip place='top' type='dark' effect='solid' />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeCalendar: state.activeCalendar,
    activeCompany: state.activeCompany,
    companiesData: state.companiesData
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('translations')(CalStyle))
